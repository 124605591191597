import React from "react";
import { BrowserRouter, Router, Route, Link, Routes } from "react-router-dom";
import { Typography, Toolbar, Button } from '@mui/material'
import { createTheme } from '@mui/material/styles';
import { signOut } from 'aws-amplify/auth';

import Home from './home';

import theRoutes from './routes';

const theme = createTheme();
const styles = {
  root: {
    display: "grid",
    gridTemplateRows: "50px auto 50px",
    gridTemplateAreas: `
      "header"
      "main"
      "footer"
      `,
    minHeight: "100vh",
    backgroundColor: "#DDD"
  },
  header: {
    gridArea: "header",
    position: "static",
    backgroundColor: "#0068A9",
    color: "white"
  },
  logoutButton: {
    fontWeight: "bold",
    color: "white"
  },

  main: {
    gridArea: "main",
    margin: "7px",
    padding: "14px"
  },
  footer: {
    gridArea: "footer"
  },
  title: {
    flexGrow: 1
  },
  text: {
    marginLeft: "1em"
  },
  link: {
    textDecoration: "none",
    "& a": {
      textDecoration: "none"
    }
  },
  menuIcon: {
    marginRight: theme.spacing(2),
    color: "black",
    "&.active": {
      backgroundColor: "#0068A9",
    }
  },
  menuButton: {
    margin: "5px",
    color: "black"
  },
  logoHeader: {
    width: "36px",
    float: "left",
    position: "relative",
    bottom: "5px"
  }
};

// ---------------- //
// Main component //
// ---------------- //
function Main(props) {
  const main = props.routes.map(routeItem => {
    if (routeItem.path === "/") return (
      <Route key={routeItem.name} exact path={routeItem.path} element={<Home />} />
      // <Route key={routeItem.name} exact path={routeItem.path} component={Home} />
    )
    return (
      // <Route key={routeItem.name} path={routeItem.path} component={routeItem.component} />
      <Route key={routeItem.name} path={routeItem.path} element={routeItem.component} />
    )
  })
  return (
    <div style={styles.main}>
      <Routes>
        {main}
        {/* <Route key={'home'} exact path={'/'} element={<Home />} /> */}
      </Routes>
     
    </div>
  )
}

// ---------------- //
// Header component //
// ---------------- //

function Header(props) {
  const handleLogout = () => {
    signOut()
      .then(data => {
        window.location.reload();
      })
      .catch(err => console.log(err));
  };

  return (
    <div style={styles.header}>
      <Toolbar>
        <Typography variant="h6" style={styles.title}>
          <img src="favicon_white.png" style={styles.logoHeader} alt="arvato logo"></img>Agent Management
        </Typography>
        <Button
          style={styles.logoutButton}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Toolbar>
    </div>
  );

}

// ---------------- //
// Footer component //
// ---------------- //
function Footer() {
  return (
    <div style={styles.footer}>
      <p></p>
    </div>
  );
}

// ---------------- //
// Layout component //
// ---------------- //
function Layout(props) {
  return (
    <div style={styles.root}>
      <BrowserRouter>
        <Header auth={props.auth} />
        {/* <div>{theRoutes()}</div> */}
        <Main routes={theRoutes()} />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default Layout;