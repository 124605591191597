import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import { signIn } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

import Layout from './components/layout';


Amplify.configure(awsconfig);
const App = ({ signOut, user }) => {
  return (
    <div className="App">
      <Layout auth={signIn}>
        <p>initial stuff</p>
      </Layout>

    </div>
  );
};

export default withAuthenticator(App);