import DashboardIcon from '@mui/icons-material/Dashboard';
import Home from './home';

function theRoutes() {
// console.log('debug: '+JSON.stringify({Home}))
    var routes = [];

    routes.push({
        name: "home",
        icon: DashboardIcon,
        path: "/",
        component: Home,
    });
    // console.log(JSON.stringify(routes))
    return routes;

}

export default theRoutes;