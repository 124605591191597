import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Paper, IconButton, Grid } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';


import { DeleteDialog, MoveDialog } from './Dialogs';
import { removeAgent, updateAgent } from '../data';
import { json } from "react-router-dom";


export const UserList = (props) => {

    const { tablelabels, tablerows, setDeleteObj, updateAgents, styles } = props;
    const hasAgents = tablerows !== undefined && tablerows.length > 0 ? true : false;

    const [deleteObj, setNewObj] = useState({});
    //delete Dialog states
    const [openDeleteDialog, changeDialogState] = useState(false);

    const title = "Agent Löschen";
    const deleteText = "Agent wirklich löschen?";

    const setDeleteDialogOpen = () => {
        changeDialogState(true);
    };

    const setDeleteDialogClosed = () => {
        changeDialogState(false)
    };
    const setObj = (obj) => {
        setNewObj(obj)
    }
    if (hasAgents) {
        return (
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table style={styles.table} aria-label="simple table">
                        <TableHead>
                            <TableRow key="header" >
                                {tablelabels.map((label, i) => (
                                    editDeleteLabel(label, i)
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                tablerows.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell key={"Lastname" + i}> {row.Lastname}</TableCell>
                                        <TableCell key={"Firstname" + i}> {row.Firstname}</TableCell>
                                        <TableCell key={"email" + i}> {row.email}</TableCell>
                                        <TableCell key={"stack" + i}> {row.Stack.Name}</TableCell>
                                        {/* <TableCell> <IconButton aria-label="edit" color="primary"><EditIcon /></IconButton></TableCell> */}
                                        <TableCell key={"button" + i}> <IconButton key={"iconb" + i} aria-label="delete" color="secondary" onClick={() => { setDeleteObj(row); setObj(row); setDeleteDialogOpen(); }} ><DeleteIcon sx={{ color: "#ff0000" }} /></IconButton></TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <DeleteDialog
                    open={openDeleteDialog}
                    setOpen={setDeleteDialogClosed}
                    onConfirm={() => { handleDeleteAgent(deleteObj) }}
                    updateFunction={updateAgents}
                    children={deleteText}
                    title={title}
                ></DeleteDialog>
            </Grid >
        )
    }
    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table style={styles.table} aria-label="simple table">
                    <TableHead>
                        <TableRow key="header" >
                            {tablelabels.map((label, i) => (
                                editDeleteLabel(label, i)
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>

                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>)
}

export const AgentList = (props) => {
    const { tablelabels, agents, lead, leads, updateAgents, setDeleteObj } = props;
    const [deleteObj, setNewObj] = useState({});
    const [moveObj, setNewMoveObj] = useState({});
    const title = "Agent Löschen";
    const moveText = "";
    const titleMove = "Agent anderem Lead zuweisen"
    const deleteText = "Agent wirklich löschen?";
    //delete Dialog state
    const [openDeleteDialog, changeDialogState] = useState(false);
    //move Dialog state
    const [openMoveDialog, changeMoveDialogState] = useState(false);

    const setDeleteDialogOpen = () => {
        changeDialogState(true);
    };

    const setDeleteDialogClosed = () => {
        changeDialogState(false)
    };

    const setMoveDialogOpen = () => {
        changeMoveDialogState(true);
    }

    const setMoveDialogClosed = () => {
        changeMoveDialogState(false)
    }

    const setObj = (obj) => {
        setNewObj(obj)
    }

    const setMoveObj = (obj) => {
        setNewMoveObj(obj);
    }

    return (<Grid item xs={11} style={{ margin: 'auto', paddingBottom: '10px' }} >
        <TableContainer component={Paper}> <Table className="table" aria-label=" simple table" style={{ margin: 'auto' }}>
            <TableHead>
                <TableRow key="header" >
                    {tablelabels.map((label, i) => (
                        editDeleteLabel(label, i)
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    agents.map((agent) => {
                        if (agent.PK === lead.PK) {
                            return (
                                <TableRow key={agent.email}>
                                    <TableCell key={"Lastname"}> {agent.Lastname}</TableCell>
                                    <TableCell key={"Firstname"}> {agent.Firstname}</TableCell>
                                    <TableCell key={"email"}> {agent.email}</TableCell>
                                    {/* <TableCell key={ "stack" + i }> { row.Stack.Name }</TableCell> */}
                                    {/* <TableCell> <IconButton aria-label="edit" color="primary"><EditIcon /></IconButton></TableCell> */}
                                    <TableCell key={"Stack"}> {JSON.stringify(agent.Stack.Name)}</TableCell>
                                    <TableCell key={"buttons"} style={{ textAlign: 'end' }}>
                                        <div>
                                            <IconButton key={"iconbd"} aria-label="delete" color="secondary" onClick={() => {
                                                setDeleteObj(agent); setObj(agent); setDeleteDialogOpen();
                                            }} ><DeleteIcon sx={{ color: "#ff0000" }} /></IconButton>
                                            <IconButton key={"iconbm"} aria-label="move" color="primary" onClick={() => {
                                                setMoveObj(agent); setMoveDialogOpen();
                                            }} ><DoubleArrowIcon /></IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    })}
            </TableBody>

        </Table></TableContainer>
        <DeleteDialog
            open={openDeleteDialog}
            setOpen={setDeleteDialogClosed}
            onConfirm={() => { handleDeleteAgent(deleteObj) }}
            updateFunction={updateAgents}
            children={deleteText}
            title={title}
        ></DeleteDialog>
        <MoveDialog
            open={openMoveDialog}
            setOpen={setMoveDialogClosed}
            onConfirm={handleMoveAgent}
            updateFunction={updateAgents}
            children={moveText}
            title={titleMove}
            leads={leads}
            agent={moveObj}
        ></MoveDialog>
    </Grid >
    )
}

function editDeleteLabel(label, index) {
    if (label === 'Edit' || label === 'Delete') {
        return <TableCell key={"edit" + index}></TableCell>
    }
    return <TableCell key={label + index}>{label}
    </TableCell>
}

async function handleMoveAgent(moveObj, leadPK) {
    let response = await updateAgent({ agent: moveObj, newLead: leadPK });
}

async function handleDeleteAgent(deleteObj) {
    let result = await removeAgent(deleteObj.SK, deleteObj.PK.split('#')[1]);
}


