import { get, put, post, del } from 'aws-amplify/api';

import { fetchAuthSession } from 'aws-amplify/auth';

const apiName = 'SUMS';
const debug = true;

async function getAgentsByLead(id, token) {
    let path = "/getAgentsByLead/" + id;
    const restOperation = get({
        apiName: apiName,
        path: path
    });
    try {
        const { body } = await restOperation.response;
        const response = await body.json();
        debug && debug && console.log('GET Stacks call succeeded: ', response);
        return response;
    } catch (e) {
        debug && console.log('GET Stacks call failed: ', JSON.parse(e.response.body));
        return e;
    }
}

async function getStacks() {
    let path = "/settings";
    const restOperation = get({
        apiName: apiName,
        path: path
    });
    try {
        const { body } = await restOperation.response;
        const response = await body.json();
        debug && console.log('GET Stacks call succeeded: ', response);
        return response;
    } catch (e) {
        debug && console.log('GET Stacks call failed: ', JSON.parse(e.response.body));
        return e;
    }
}

async function getAllAgents() {
    let path = "/agent"
    const restOperation = get({
        apiName: apiName,
        path: path
    });
    try {
        const { body } = await restOperation.response;
        const response = await body.json();
        debug && console.log('GET Agents call succeeded: ', response);
        return response;
    } catch (e) {
        debug && console.log('GET Agents call failed: ', JSON.parse(e.response.body));
        return e;
    }
}

async function getAllLeads() {
    let path = "/lead"
    const restOperation = get({
        apiName: apiName,
        path: path
    });
    try {
        const { body } = await restOperation.response;
        const response = await body.json();
        debug && console.log('GET Leads call succeeded: ', response);
        return response;
    } catch (e) {
        debug && console.log('GET Leads call failed: ', JSON.parse(e.response.body));
        return e;
    }
}

export async function Data() {
    let data = { agents: [], stacks: [], isAdmin: false };
    // get the current auth token
    let tokens = (await fetchAuthSession()).tokens ?? {};
    let user = Object.assign({}, tokens);
    let groups = tokens.idToken.payload["cognito:groups"] !== undefined ? tokens.idToken.payload["cognito:groups"] : [];
    // token = token.idToken.jwtToken;
    let token = tokens.idToken;

    debug && console.log('DEBUG GROUPS: ', groups)
    if (groups.includes("Admin")) {
        data.isAdmin = true;
        data.userEmail = user.idToken.payload.email;
        data.stacks = await /* The `getStacks` function is making a GET request to the API endpoint
        `/settings` using the `aws-amplify/api` library. It is fetching data
        related to settings from the API and returning the response in JSON
        format. The function handles both successful and failed API calls by
        logging appropriate messages to the console. */
        getStacks();
        // debug && console.log('stacks, ', data.stacks)
        //get leads
        data.leads = await getAllLeads();
        // debug && console.log('all leads, ', data.leads);

        //get agents 
        data.agents = await getAllAgents();

        // debug && console.log('data: ', JSON.stringify(data))

    }
    else {

        try {
            let result = await getAgentsByLead(user.idToken.payload.email, token);
            data.stacks = await getStacks();
            result.forEach(element => {
                if (element.SK === "LEAD") {
                    data.userName = element.Firstname + " " + element.Lastname;
                    data.userEmail = element.PK.split('#')[1];
                    data.stack = element.Stack;
                } else {
                    data.agents.push(element)
                }
            });
        } catch (err) {
            debug && console.log(err);
        };

        try {
            let stacks = await getStacks();
            data.stacks = stacks;
        } catch (e) {
            debug && console.log(e)
        };
    };
    return data;
}

export async function addAgent(agent) {
    let path = "/agent/save";
    const restOperation = post({
        apiName: apiName,
        path: path,
        options: {
            body: agent
        }
    });
    try {
        const { body } = await restOperation.response;
        const response = await body.json();
        debug && console.log('POST Agents call succeeded: ', JSON.stringify(response));
        return JSON.parse(response);
        // const response = await restOperation.response;
        // console.log('debug123: ',response)
        // debug && console.log('POST Agents call succeeded!');
        // return JSON.parse(response);

    } catch (e) {
        // debug && console.log('POST Agents call failed: ', JSON.parse(e.response));
        debug && console.log('POST Agents call failed');
        return e;
    }
}

export async function updateAgent(agent) {
    let path = "/agent/save";
    const restOperation = put({
        apiName: apiName,
        path: path,
        options: {
            body: agent
        }
    });
    try {
        const { body } = await restOperation.response;
        const response = await body.json();
        debug && console.log('PUT Agents call succeeded: ', response);
        return response;
    } catch (e) {
        debug && console.log('PUT Agents call failed: ', JSON.parse(e.response.body));
        return e;
    }
}

export async function removeAgent(agentEmail, leadId) {
    let path = "/agent/" + leadId + "/" + agentEmail;
    const restOperation = del({
        apiName: apiName,
        path: path
    });

    try {
        await restOperation.response;
        debug && console.log('DELETED Agents call succeeded');
        return 'DELETED Agents call succeeded';
    } catch (e) {
        debug && console.log('DELETE Agents call failed!' + JSON.stringify(e));
        return e;
    }
}

export async function AgentWithEmailExists(email) {
    let path = `/AgentEmailExists/${email}`;
    const restOperation = get({
        apiName: apiName,
        path: path
    });
    try {
        const { body } = await restOperation.response;
        const response = await body.json();
        debug && console.log('GET Agents with Email call succeeded: ', response);
        return response;
    } catch (e) {
        debug && console.log('GET Agents with Email call failed: ', JSON.parse(e));
        return e;
    }
}

export async function addLead(lead) {
    let path = '/lead'
    const restOperation = post({
        apiName: apiName,
        path: path,
        options: {
            body: lead
        }
    });
    try {
        const { body } = await restOperation.response;
        const response = await body.json();
        debug && console.log('POST Lead call succeeded: ', response);
        // debug && console.log('debug lead ', lead);
        return response;
    } catch (e) {
        debug && console.log('POST Lead call failed: ', JSON.parse(e.response.body));
        return e;
    }
}

export async function removeLead(id) {
    let path = `/lead/${id}`;

    const restOperation = del({
        apiName: apiName,
        path: path
    });

    try {
        const { body } = await restOperation.response;
        const response = await body.json();
        debug && console.log('DELETE Lead call succeeded: ', response);
        return response;
    } catch (e) {
        debug && console.log('DELETE Lead call failed: ', JSON.parse(e.response.body));
        return e;
    }
}

export default Data;