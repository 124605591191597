import { createTheme } from '@mui/material/styles';
const theme = createTheme();

const theTheme = {

    root: {
        flexGrow: 1,
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto",
        gridTemplateAreas: `
        "tools tools shortcuts"
        `,
        gridGap: "1em",
    },
    item: {
        padding: theme.spacing(2),
    }
}
export default theTheme;