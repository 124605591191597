import React, { useEffect, useState } from "react";

import { Button, Grid, Typography, Toolbar, Tooltip, IconButton, Snackbar } from '@mui/material'

// import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/system";


import { Data, addAgent, addLead, removeLead } from './data';
import { AddDialog, AddLeadDialog, DeleteDialog } from './elements/Dialogs';
import myStyles from './styles';

import DeleteIcon from '@mui/icons-material/Delete';


import { UserList, AgentList } from './elements/list';


// function theAlert(props) {
//     return <Alert elevation={6} variant="filled" {...props} />;
// }
export default function Home() {
    const [data, setData] = useState({});
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [stacks, setStacks] = useState([]);
    const [leadStack, setLeadStack] = useState({});
    // const classes = useStyles();

    useEffect(() => {
        const getData = async (setData, setLead, setUserEmail) => {
            let data = await Data();
            setData(data);
            setLead(data.userName);
            setUserEmail(data.userEmail);
            setLeadStack(data.stack);
        }

        const getStacks = async (data, setStacks) => {
            let stacks = data.stacks;
            if (stacks !== undefined) {
                setStacks(stacks[0].Stacks);
            }
            setStacks([])
        }
        getData(setData, setUserName, setUserEmail);
        getStacks(data, setStacks);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (data.agents === undefined) {
        // return (<div className={classes.root}></div>)
        return (<div></div>)
    } else {
        return (
            ((data.hasOwnProperty("isAdmin") && data.isAdmin === true && data.agents.length !== 0) ?
                <AdminView data={data} styles={myStyles} />
                : <LeadView data={data} styles={myStyles} username={userName} leadId={userEmail} leadStack={leadStack} />))
    }
}

function LeadView(props) {
    const { data, styles, username, leadId, leadStack } = props;
    const label = ["Nachname", "Vorname", "Email", "Stack", 'Delete'] //'Edit',
    const [agents, setAgents] = useState([]);
    const [deleteObject, setDeleteObject] = useState(undefined);
    const [openAddDialog, changeDialogState] = useState(false);

    useEffect(() => {
        getAgents(data)
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const setAddDialogOpen = () => {
        changeDialogState(true);
    };

    const setAddDialogClosed = () => {
        changeDialogState(false)
    };

    const getAgents = (data) => {
        setAgents(data.agents)
    }

    const setAgentsNew = (agent) => {
        setAgents(agents => [...agents, agent])
    };

    const removeAgent = () => {
        const list = agents.filter((item) => item !== deleteObject);
        setAgents(list);
    }

    let setDeleteValue = (value) => {
        setDeleteObject(value)
        return value;
    };

    return (<div style={styles.root}>   <Grid container >
        <Typography variant="h4" style={styles.title}>{`Teamleiter ${username}`}</Typography>
        {<Grid item xs={12} style={styles.item}>
            <Button variant="contained" style={{ backgroundColor: "#0068A9" }} onClick={setAddDialogOpen}>neuer Agent</Button>
        </Grid>
        }
        <UserList styles={styles} tablelabels={label} tablerows={agents} leadId={leadId} setDeleteObj={setDeleteValue} updateAgents={removeAgent} >
        </UserList>
    </Grid>
        <AddDialog
            openDialog={openAddDialog}
            setOpenDialog={setAddDialogClosed}
            onConfirm={handleAddAgent}
            updateFunction={setAgentsNew}
            leadId={leadId}
            leadStack={leadStack}
            data={data}>
        </AddDialog></div>)
}

function AdminView(props) {
    const { data, styles } = props;
    const [leads, setLeads] = useState([]);
    const [agents, setAgents] = useState([]);
    const [openSnackbar, setSnackbarOpen] = useState(false);
    const [openAddLeadDialog, changeDialogState] = useState(false);
    const [deleteObj, setNewObj] = useState({});
    const [deleteObject, setDeleteObject] = useState(undefined);
    const Label = ["Nachname", "Vorname", "Email", 'Stack', 'Delete'];
    const title = "Lead Löschen";
    const deleteText = "Lead wirklich löschen?";
    //delete Dialog state
    const [openDeleteDialog, changeDeleteDialogState] = useState(false);

    useEffect(() => {
        getLeads(data)
        getAgents(data)
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    const setObj = (obj) => {
        setNewObj(obj)
    }
    const setDeleteDialogOpen = () => {
        changeDeleteDialogState(true);
    };

    const setDeleteDialogClosed = () => {
        changeDeleteDialogState(false)
    };

    const setAddDialogOpen = () => {
        changeDialogState(true);
    };

    const setAddDialogClosed = () => {
        changeDialogState(false)
    };

    const getLeads = (data) => {
        setLeads(data.leads)
    }

    const getAgents = (data) => {
        setAgents(data.agents)
    }

    const updateLeads = () => {
        setLeads(leads.filter((item) => item !== deleteObj))
    }

    const addLead = (lead) => {
        setLeads(leads => [...leads, lead])
    }

    const updateAgents = (agent) => {
        setAgents(agents.filter((item) => item !== deleteObject))
    }

    const handleClose = (event, reason) => {
        if (reason === 'click away') {
            return;
        }
        setSnackbarOpen(false);
    };
    let setDeleteValue = (value) => {
        setDeleteObject(value)
        return value;
    };

    return (<div style={styles.root}>
        <Typography variant="h4" style={styles.title}>{`Admin ${data.userEmail}`}</Typography>
        <Grid item xs={12} style={styles.item}>
            <Button variant="contained" style={{ backgroundColor: "#0068A9" }} onClick={() => { setAddDialogOpen() }}>neuer Lead</Button>
        </Grid>

        {/* Snackbar*/}
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
            <theAlert onClose={handleClose} severity="warning">
                Lead darf keine Agents mehr haben
            </theAlert>
        </Snackbar>
        {/* LeadContainer*/}
        {leads.map((lead) => (
            < Grid container key={lead.Lastname + lead.Firstname} >
                <div style={{ background: 'silver', marginBottom: '10px', width: '100%', borderRadius: "4px" }}>
                    <Toolbar>
                        <Typography style={styles.title} variant="h6" id="tableTitle" component="div">
                            {lead.Lastname}, {lead.Firstname}
                        </Typography>
                        <Tooltip title="Delete Lead">
                            <IconButton aria-label="delete lead" onClick={() => { if (agents.filter(agent => agent.PK === lead.PK).length === 0) { console.log("deletedialog"); setObj(lead); setDeleteDialogOpen(); } else { setSnackbarOpen(true); } }}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    {/* AgentTable*/}
                    <AgentList styles={styles} tablelabels={Label} lead={lead} agents={agents} leads={leads} updateAgents={updateAgents} setDeleteObj={setDeleteValue}></AgentList>
                </div>
            </Grid>
        ))
        }
        <DeleteDialog
            open={openDeleteDialog}
            setOpen={setDeleteDialogClosed}
            onConfirm={() => { handleDeleteLead(deleteObj) }}
            updateFunction={updateLeads}
            children={deleteText}
            title={title}>
        </DeleteDialog>
        <AddLeadDialog
            openDialog={openAddLeadDialog}
            setOpenDialog={setAddDialogClosed}
            onConfirm={handleAddLead}
            updateFunction={addLead}
            data={data} />
    </div>
    )
}

async function handleDeleteLead(deleteObj) {
    let result = await removeLead(deleteObj.email);
}

async function handleAddLead(lead, updateFunction) {
    let response = await addLead(lead);
    if (response !== undefined && response.$metadata.httpStatusCode === 200) {
        // updateFunction(response);
        updateFunction(lead);
    }
}

async function handleAddAgent(agent, updateFunction) {
    console.log('debug add agent: ' + JSON.stringify(agent))
    let response = await addAgent(agent);
    if (response !== undefined) {
        updateFunction(response);
    }
}

