import React, { useState } from 'react';
import { DialogTitle, Dialog, DialogContent, DialogActions, TextField, Button, FormControl, Select, Input } from '@mui/material'
import { AgentWithEmailExists } from '../data';
import { Margin } from '@mui/icons-material';

export const MoveDialog = (props) => {
    const { open, setOpen, title, updateFunction, onConfirm, agent, leads } = props;
    const [selectedLead, setLead] = useState({});

    const handleChange = (event) => {
        if (event.target.id === "leadSelect") {
            setLead(event.target.value)
        }
    }

    let optionTemplate =
        [(<option key={0} value={0}></option>)].concat(leads.map(v => (
            <option key={v.Lastname + ', ' + v.Firstname} value={v.PK}>{v.Lastname + ", " + v.Firstname}</option>
        )));

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby='move-dialog'
        >
            <DialogTitle id='move-dialog'>{title}</DialogTitle>
            <DialogContent>
                <label>Lead </label>
                <Select
                    label="lead"
                    variant="outlined"
                    labelId="select-lead-label"
                    native
                    id="leadSelect"
                    onChange={handleChange}
                    input={<Input id="select-input" />}
                >
                    {optionTemplate}
                </Select>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" style={{ backgroundColor: "#e0e0e0", color: 'black' }} onClick={() => setOpen(false)}>Cancel</Button>
                <Button variant="contained" onClick={() => {
                    onConfirm(agent, selectedLead); updateFunction(agent); const ag = agent; ag.PK = selectedLead; updateFunction(ag); setOpen(false);
                }} style={{ backgroundColor: "#0068A9" }} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export const DeleteDialog = (props) => {
    const { open, title, children, setOpen, onConfirm, updateFunction } = props;

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby='delete-dialog'
        >
            <DialogTitle id='delete-dialog'>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button variant="contained" style={{ backgroundColor: "#e0e0e0", color: 'black' }} onClick={() => setOpen(false)}>Cancel</Button>
                <Button variant="contained" onClick={() => { setOpen(false); onConfirm(); updateFunction() }} style={{ backgroundColor: "#0068A9" }} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export const AddLeadDialog = (props) => {
    const { openDialog, setOpenDialog, onConfirm, updateFunction, data } = props;
    const [errorText, setError] = useState("");
    const availablestacks = data.hasOwnProperty("stacks") ? stackvalues(data.stacks) : [];
    const [lead, setLead] = useState({ SK: 'LEAD', PK: '', Firstname: '', Lastname: '', email: '',Stack: availablestacks[0] });

    const handleChange = (event) => {
        let email = "";
        let newLead = lead;
        if (event.target.id === 'new-email') {
            email = event.target.value;
            newLead.email = email;
            newLead.PK = `LEAD#${email}`
        }
        if (event.target.id === 'new-firstname') {
            newLead.Firstname = event.target.value;
        }
        if (event.target.id === 'new-lastname') {
            newLead.Lastname = event.target.value;
        }
        if (event.target.id === 'stackSelect') {
            let stack = availablestacks.find(stack => stack.Name === event.target.value)
            newLead.Stack = stack;
        }
        setLead(newLead);
    }
    let optionTemplate = availablestacks.map(v => (
        <option key={v.URL + '#' + v.Name} value={v.Name}>{v.Name}</option>
    ));
    return (
        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby='add-dialog'
        // autoDetectWindowHeight={false}
        // autoScrollBodyContent={false}
        >
            <div
                style={{ width: 500 }}>
                <DialogTitle id="add-dialog-title">Neuer Lead</DialogTitle>
                <DialogContent
                >
                    <form className="add-form">
                        <FormControl className="add-form" style={{ minWidth: '100%' }}>
                            <TextField
                                autoComplete="off"
                                required={true}
                                variant="outlined"
                                label="Vorname"
                                id='new-firstname'
                                type="text"
                                onChange={handleChange}
                                style={{ padding: 10 }}
                                margin="dense"
                            ></TextField>
                            <TextField
                                autoComplete="off"
                                required={true}
                                variant="outlined"
                                label="Nachname"
                                id='new-lastname'
                                type="text"
                                onChange={handleChange}
                                style={{ padding: 10 }}
                                margin="dense"
                            ></TextField>
                            <TextField
                                autoComplete="off"
                                required={true}
                                variant="outlined"
                                label="E-Mail"
                                id='new-email'
                                type="text"
                                onChange={handleChange}
                                //       setEmail={ text => setEmail(text) }
                                style={{ padding: 10 }}
                                margin="dense"
                            ></TextField>
                            <label style={{ color: 'red' }}>{errorText}</label>

                            <label>Stack</label>
                            <Select
                                label="Stack"
                                variant="outlined"
                                labelId="select-stack-label"
                                native
                                id="stackSelect"
                                required={true}
                                onChange={handleChange}
                                style={{ margin: 10 }}
                                input={<Input id="select-input" />}
                            >
                                {optionTemplate}
                            </Select>

                        </FormControl>
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: "#e0e0e0", color: 'black' }} onClick={() => { setError(""); setOpenDialog(false) }}  >
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={async function handle() {
                        //   let result = await validateForm(lead, setError);
                        //  if (result === true) {
                        setError("");
                        setOpenDialog(false);
                        onConfirm(lead, updateFunction);
                        // }
                    }} style={{ backgroundColor: "#0068A9" }} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </div>
        </Dialog >
    )

}

function stackvalues(availablestacks) {
    if (availablestacks.length > 0 && availablestacks[0].hasOwnProperty("Stacks")) {
        return availablestacks[0].Stacks.map((stack) => {
            return { Name: stack.Name, URL: stack.URL };
        })
    }
    return [];
}

export const AddDialog = (props) => {
    let { openDialog, setOpenDialog, onConfirm, updateFunction, leadId, leadStack, data } = props;
    const [Agent, setAgent] = useState({});
    const availablestacks = data.hasOwnProperty("stacks") ? stackvalues(data.stacks) : [];
    // const emailEnding = "@sums-online.de"
    const emailEnding = "@sums-online.de";
    const [email, setEmail] = useState("");

    const [errorText, setError] = useState("");
    const handleChange = (event) => {
        let agent = Agent;
        agent.PK = `LEAD#${leadId}`;
        agent.Stack = availablestacks[0];
        if (event.target.id === 'new-email') {
            agent.SK = event.target.value;
            agent.email = event.target.value;
            setEmail(event.target.value);
        }
        if (event.target.id === 'new-firstname') {

            agent.Firstname = event.target.value;
            setEmail(emailPrefix(agent.Firstname + '.', agent.Lastname) + emailEnding);
        }
        if (event.target.id === 'new-lastname') {
            agent.Lastname = event.target.value;
            setEmail(emailPrefix(agent.Firstname + '.', agent.Lastname) + emailEnding);
        }
        agent.Stack = leadStack;
        agent.SK = emailPrefix(agent.Firstname + '.', agent.Lastname) + emailEnding;
        agent.email = emailPrefix(agent.Firstname + '.', agent.Lastname) + emailEnding;
        setAgent(agent);

    }

    return (
        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby='add-dialog'
        // autoDetectWindowHeight={false}
        // autoScrollBodyContent={false}
        >
            <div
                style={{ width: 500 }}>
                <DialogTitle id="add-dialog-title">Neuer Agent</DialogTitle>
                <DialogContent
                >
                    <form className="add-form">
                        <FormControl className="add-form" style={{ minWidth: '100%' }}>
                            <TextField
                                // autoComplete="off"
                                required={true}
                                variant="outlined"
                                label="Vorname"
                                id='new-firstname'
                                // type="text"
                                onChange={handleChange}
                                style={{ padding: 10 }}
                                margin="dense"
                            ></TextField>
                            <TextField
                                autoComplete="off"
                                required={true}
                                variant="outlined"
                                label="Nachname"
                                id='new-lastname'
                                type="text"
                                onChange={handleChange}
                                style={{ padding: 10 }}
                                margin="dense"
                            ></TextField>
                            <TextField
                                autoComplete="off"
                                required={true}
                                variant="outlined"
                                label="E-Mail"
                                id='new-email'
                                type="text"
                                value={email}
                                InputProps={{ readOnly: true, }}
                                defaultValue=""
                                // onChange={handleChange}
                                // setEmail={text => setEmail(text)}
                                style={{ padding: 10 }}
                                margin="dense"
                            ></TextField>
                            <label style={{ color: 'red' }}>{errorText}</label>
                            <TextField
                                autoComplete="off"
                                required={true}
                                variant="outlined"
                                label="Stack"
                                id='stackSelect'
                                type="text"
                                value={leadStack.Name}
                                InputProps={{ readOnly: true, }}
                                defaultValue={leadStack.Name}
                                // onChange={handleChange}
                                // setEmail={text => setEmail(text)}
                                style={{ padding: 10 }}
                                margin="dense"
                            ></TextField>

                        </FormControl>
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: "#e0e0e0", color: 'black' }} onClick={() => { setEmail(""); setError(""); setOpenDialog(false); }} >
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={async function handle() {
                        let result = await validateForm(Agent, setError);
                        if (result === true) {
                            setError("");
                            setOpenDialog(false);
                            onConfirm(Agent, updateFunction);
                            setEmail("");
                        }

                    }} style={{ backgroundColor: "#0068A9" }} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </div>
        </Dialog >
    )
}




async function validateForm(Agent, setError) {
    let result = true;
    console.log("validate form:", Agent)

    //E-Mail nicht leer
    if (Agent.SK === undefined || Agent.SK === "") {
        result = false;
        setError("E-Mail fehlt");
        return result;
    }
    //E-Mail im richtigen Format
    if (validateEmail(Agent.SK) !== true) {
        result = false;
        setError("E-Mail: nicht im richtigen Format")
        return result;
    }

    //firstName nicht leer
    if (Agent.Firstname === undefined || Agent.Firstname === "") {
        result = false;
        setError("Vorname: darf nicht leer sein");
        return result;
    }

    //firstName mehr als 2 Zeichen und keine Sonderzeichen
    if (validateTextlengthAndChar(Agent.Firstname) !== true) {
        result = false;
        setError("Vorname muss mindestens 3 Buchstaben haben und keine Sonderzeichen beinhalten");
        return result;
    }

    //lastName
    if (Agent.Lastname === undefined || Agent.Lastname === "") {
        result = false;
        setError("Nachname: darf nicht leer sein");
        return result;
    }
    //lastName mehr als 2 Zeichen und keine Sonderzeichen
    if (validateTextlengthAndChar(Agent.Lastname) !== true) {
        result = false;
        setError("Nachname muss mindestens 3 Buchstaben haben und keine Sonderzeichen beinhalten");
        return result;
    }

    let checkEMail = await AgentWithEmailExists(Agent.SK);
    if (checkEMail.length > 0) {
        result = false;
        setError("E-Mail bereits: vorhanden, bitte ändern")
        return result;
    }

    console.log(result)
    return result;
}

function emailPrefix(firstname, lastname) {
    if (firstname !== undefined && lastname === undefined) {
        let firstName = firstname.replace(/ /g, "");
        return `${firstName}`.toLowerCase();
    }
    if (lastname !== undefined && firstname === undefined) {
        let lastName = lastname.replace(/ /g, "");
        return `${lastName}`.toLowerCase()
    }
    if (firstname !== undefined && lastname !== undefined) {
        let firstName = firstname.replace(/ /g, "");
        let lastName = lastname.replace(/ /g, "");
        return `${firstName}${lastName}`.toLowerCase()
    } else { return "" };
}

function validateEmail(email) {
    let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(email);
}

function validateTextlengthAndChar(text) {
    let re = /^[a-zA-Z]+$/;
    let result = true;

    if (text.length < 2) {
        result = false;
        return result;
    }

    if (re.test(text) !== true) {
        result = false;
        return result
    }
    return result;

}
